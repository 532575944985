<template>
  <div class="zq--radio-btn">
    <label class="zq--radio-btn-container">
      <input
          type="radio"
          :value="value"
          :name="value"
          @change="update"
          v-model="model">
      <span class="checkmark"></span>
      <span class="zq--radio-btn--label">{{ label }}</span>
    </label>
  </div>
</template>


<script>
export default {
  props: {
    value: String,
    modelProp: String,
    label: String
  },
  data() {
    return {
      model: ''
    }
  },
  created() {
    this.model = this.modelProp;
  },
  methods: {
    update({target}) {
      this.$emit('update', target.value);
    }
  },
  watch: {
    modelProp(val) {
      this.model = val;
    }
  }
}
</script>