<template>
  <div class="csv-modal">
    <CModal
        title="Update from CSV"
        centered
        :show.sync="modal"
        @update:show="updateModal"
        addContentClasses="modal-wrap-content"
    >
      <CRow
          class="import-csv"
          @drop.prevent="addFile" @dragover.prevent>
        <CCol>
          <span class="import-csv-subtitle">Browse your computer or drag and drop</span>
        </CCol>
        <CCol>
          <div class="actions-wrapper">
            <div class="actions">
              <!--        BROWSE FIELD      -->
              <CCol class="browse-field">
                <label class="import-csv-label" for="uploadFieldName">
                  <div class="csv-icon zq--custom-button">
                    <i class="fa fa-upload"></i>
                    <span class="csv-icon--label">Browse</span>
                  </div>
                  <!--                  <span> Browse...</span>-->
                  <input
                      type="file"
                      multiple
                      accept=".csv"
                      ref="csvInput"
                      id="uploadFieldName"
                      name="uploadFieldName"
                      @change="parse($event)"
                      class="input-file"
                  />
                </label>

                <div class="csv-name">
                  <div>{{ textShorten(csvName) }}</div>
                </div>
              </CCol>
              <!--  -->
              <CCol class="pb-3" style="display:inline-flex; flex-direction: column; width: auto !important;">
                <div class="d-inline-flex justify-content-between align-items-center">
<!--                  <ClRadio-->
<!--                      class="csv-fields&#45;&#45;control"-->
<!--                      @update="handleUpdatePicked"-->
<!--                      value="append"-->
<!--                      :label="config.appendTitle"-->
<!--                      :modelProp="picked"/>-->
<!--                  <IconWithTooltip class="ml-1 mb-1" :text="descriptions ? descriptions.appendTitle : ''"/>-->
                </div>

                <div class="d-inline-flex justify-content-between align-items-center">
<!--                  <ClRadio-->
<!--                      class="csv-fields&#45;&#45;control"-->
<!--                      :class="'d-flex align-items-center'"-->
<!--                      @update="handleUpdatePicked"-->
<!--                      value="replace"-->
<!--                      :label="config.replaceTitle"-->
<!--                      :modelProp="picked"/>-->
<!--                  <IconWithTooltip class="ml-1 mb-1" :text="descriptions ? descriptions.replaceTitle : ''"/>-->
                </div>

                <div class="d-inline-flex justify-content-between align-items-center">
<!--                  <ClCheckbox-->
<!--                      class="csv-fields&#45;&#45;control"-->
<!--                      :valueProp="createOrMissing"-->
<!--                      :label="config.createTitle"-->
<!--                      :checkedProp="createOrMissing"-->
<!--                      @handler="createOrMissingHandler"-->
<!--                  />-->
<!--                  <IconWithTooltip class="ml-1 mt-1" :text="descriptions ? descriptions.createTitle : ''"/>-->
                </div>
              </CCol>
            </div>
          </div>

        </CCol>
      </CRow>
      <!--      FOOTER-->
      <template #footer-wrapper class="mt4">
        <div class="csv-footer">
          <div class="csv-footer-buttons">
            <CButton
                :class="btnClassClose"
                variant="outline"
                @click="closeModal"
            >
              Cancel
            </CButton>
            <CButton
                :class="btnClassSuccess"
                shape="square"
                variant="ghost"
                @click="confirm"
            >
              Confirm
            </CButton>
          </div>
        </div>
      </template>
    </CModal>
  </div>

</template>

<script>
import {mapGetters} from 'vuex';
import Papa from "papaparse";
import {textShorten} from '@/utils/textUtils';
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import ClCheckbox from '@/shared/components/formComponents/ClCheckbox';
import ClRadio from '@/shared/components/formComponents/ClRadio';

export default {
  name: 'CsvImport',
  components: {
    ClCheckbox,
    ClRadio,
    IconWithTooltip
  },
  props: {
    descriptions: Object,
    modalShow: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default() {
        return {
          appendTitle: 'Append group information to existing members',
          replaceTitle: 'Replace group information for existing members',
          createTitle: "Create new member on missing entries"
        }
      }
    },
  },
  data() {
    return {
      picked: 'append',
      csvName: '',
      modal: false,
      parseDate: null,
      createOrMissing: false,
      file: null
    }
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    btnClassSuccess() {
      return this.theme === 'main' ? 'ml-2 zq--responsive-button__common' : 'ml-2 csv-update-btn';
    },
    btnClassClose() {
      return this.theme === 'main' ? 'ml-2 zq--custom-button' : 'ml-2  csv-close-btn';
    },
  },
  watch: {
    modalShow(val) {
      this.modal = val;
      if (!val) {
        this.csvName = ''
      }
    },
    picked(val) {
      console.log(val)
    }
  },
  methods: {
    textShorten(text) {
      return textShorten(text, 55);
    },
    handleUpdatePicked(val) {
      this.picked = val;
    },
    confirm() {
      if (this.file) {
        this.$emit('load-csv', this.file)
        this.$emit('toggle-modal', true)
      }
    },
    updateModal(val) {
      this.$emit('toggle-modal', val)
    },
    addFile(e) {
      const droppedFiles = e.dataTransfer.files;
      this.parseCsv(droppedFiles[0]);
    },
    closeModal() {
      this.$refs.csvInput.value = '';
      this.$emit('toggle-modal', false)
      this.csvName = '';
    },
    parseCsv(file) {
      this.file = file
      this.csvName = file.name;
      // if (file) {
      //   this.csvName = file.name;
      //   const reader = new FileReader();
      //   reader.onload = e => {
      //     this.parseDate = Papa.parse(e.target.result, {
      //       header: true,
      //     });
      //   };
      //   reader.readAsText(file);
      // }
    },
    parse(event) {
      const file = event.target.files[0];
      this.parseCsv(file)
    },
    createOrMissingHandler(obj) {
      this.createOrMissing = obj.value
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/mixins.scss";

$browse-fields-radius: 5px;
//  Popup

.csv-modal {
  .modal-wrap-content {
    background: #f3f3f3;

    .toggle__text {
      margin-left: 5px;
    }
  }


  .modal-header {
    background: #f3f3f3;
    border-bottom: none;
    padding: 1rem 1rem 0.2rem 1rem;

    .close {
      outline: none;
    }

    h5 {
      font-weight: bold;
    }
  }

  .modal-body {
    padding: 0;
    margin-bottom: 1rem;
  }

  .import-csv-subtitle {
    color: #a4a4a4;
    font-size: 14px;
    font-weight: 600;
  }

  .import-csv {
    background: #f3f3f3;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    margin: 0;
  }

  .csv-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    margin-right: 5px;
    background: transparent;
    border: 1px solid black;
    border-radius: 7px;
    min-height: 40px;

    span {
      color: black;
      margin-left: .5rem;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
    }

    i {
      color: #282f37;
    }

    &:hover {
      background-color: #636f83;
      border-color: #636f83;
      color: white;

      span {
        color: white;
      }

      i {
        color: white;
      }
    }
  }


  .input-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .browse-field {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin: 1rem 0.8rem;
    width: 95%;
    padding: 0;
    min-height: 30px;

    .csv-name {
      flex: auto;
      margin-right: .5rem;
      height: 40px;
      padding: 10px 24px 8px 16px;
      border-radius: 7px;
      background-color: #ffffff;
      border: 1px solid grey;
      overflow: hidden;

      div {
        overflow: hidden;
        height: 20px;
      }
    }
  }

  .import-csv-label {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: 700;
    //background-color: #48c5a3;
    //border-color: #50c7a7;
    color: #ffffff;
    font-size: 12px;
    cursor: pointer;
    outline: none;
    //width: 95px;
    line-height: 1.42857;
    margin-bottom: 0;
    border-bottom-right-radius: $browse-fields-radius;
    border-top-right-radius: $browse-fields-radius;
  }


  .actions-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 0.8rem;


    .actions {
      background: white;
      border-radius: 8px;
    }

    .csv-fields--control{
      margin-right: .5rem;
    }

    //.csv-fields--control {
    //  min-width: 300px;
    //  margin-right: .2rem;
    //
    //  @include media-breakpoint-down('400px') {
    //    min-width: 97%;
    //    margin-right: .1rem;
    //  }
    //}
  }


  .csv-footer {
    padding: 10px 15px;
    background-color: white;
    color: #5c7174;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;


    .csv-close-btn, .csv-update-btn {
      color: white;
      border-radius: 5px;
      outline: none;

      &:focus {
        box-shadow: none;
      }
    }

    .csv-update-btn {
      background-color: #2eb85c;
      color: #ffffff;

      &:hover {
        background: darken(#2eb85c, 5%);
      }
    }

    .csv-close-btn {
      border-color: #404040;
      color: #404040;

      &:hover {
        background-color: #636f83;
        border-color: #636f83;
        color: white;
      }
    }
  }

  .csv-footer-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media screen and (max-width: 480px) {
      width: 100%;
    }
  }

  .form-check, .form-check-label {
    font-size: 0.8rem;
  }

  .field-required {
    color: red;
  }
}


</style>
